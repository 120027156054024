import React from 'react'
import styled from 'styled-components'

import SidebarForm from '../../molecules/SidebarForm'
import ProgramsAcquisitionTitle from '../../molecules/ProgramsAcquisitionTitle'
import ProgramsAcquisitionBackground from '../../molecules/ProgramsAcquisitionBackground'
import ProgramsAcquisitionDescription from '../../molecules/ProgramsAcquisitionDescription'

const SUBMIT = 'https://hooks.zapier.com/hooks/catch/2114713/o8qjm54'

const Container = styled.div`
  display: flex;
`

const FormSection = styled.div`
  padding: 32px 40px;
  height: 100vh;
  overflow: auto;
`

const ProgramsAcquisitionDesktop = ({ id, city, price, type }) => (
  <Container>
    <ProgramsAcquisitionBackground
      id={id}
      type={type}
      price={price} />
    <FormSection>
      <ProgramsAcquisitionTitle city={city} type={type} />
      <ProgramsAcquisitionDescription city={city} type={type} />
      <SidebarForm
        city={city}
        submit={SUBMIT}
        redirection='/programme-neuf/demande-acquisition/success/' />
    </FormSection>
  </Container>
)

export default ProgramsAcquisitionDesktop
