import React from 'react'
import styled from 'styled-components'
import { get, isEqual } from 'lodash'

import media from '../../../utils/media'

const Container = styled.h1`
  display: flex;
  flex-direction: column;

  ${media.greaterThan('lg')`
    max-width: 325px;
  `}
`

const TitleText = styled.span`
  font-family: 'Source Sans Pro';
  font-size: 22px;
  font-weight: bold;
  line-height: 1.45;
  color: ${({ theme }) => get(theme, 'blue')};

  ${media.lessThan('sm')`
    font-size: 18px;
    line-height: 1.5;
  `}
`

const CityText = styled.span`
  font-family: 'Source Sans Pro';
  font-size: 36px;
  font-weight: bold;
  line-height: 1.11;
  color: ${({ theme }) => get(theme, 'brightOrange')};
  text-transform: uppercase;

  ${media.lessThan('sm')`
    font-size: 29px;
    line-height: 1.38;
  `}
`

const ProgramsAcquisitionTitle = ({ city }) => (
  <Container>
    <TitleText>
      Tous les programmes immobiliers Neufs
    </TitleText>
    <CityText>
      {isEqual(city, 'Ile de France') ? 'en' : 'à'} {city}
    </CityText>
  </Container>
)

export default ProgramsAcquisitionTitle
