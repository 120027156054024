import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import { useStaticQuery, graphql } from 'gatsby'
import { get, nth, first, isEqual } from 'lodash'

import Logo from '../../atoms/Logo'
import media from '../../../utils/media'
import numeral from '../../../config/number'

const StyledImageSection = styled(BackgroundImage)`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  padding: 32px 24px;
  justify-content: space-between;
  min-height: 440px;

  ${media.greaterThan('lg')`
    height: 100vh;
    flex-direction: row;
    position: fixed; 
    overflow-y: auto;
    top: 0; right: 0; bottom: 0; left: 0;
  `}
`

const LogoContainer = styled.div`
  ${media.lessThan('lg')`
    display: none;
  `}
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${media.greaterThan('lg')`
    padding-top: 55px;
  `}
`

const DataItem = styled.div`
  max-width: 268px;
  border-radius: 15px;
  padding: 11px 16px;
  background-color: ${({ theme }) => get(theme, 'white', '#FFF')};
`

const DataText = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  color: ${({ theme }) => get(theme, 'blue')};

  ${media.lessThan('lg')`
    font-size: 16px;
    line-height: 1.19;
  `}

  & > b {
    color: ${({ theme }) => get(theme, 'brightOrange')};
  }

  & > span {
    font-size: 16px;
    font-weight: normal;

    ${media.lessThan('lg')`
      font-size: 14px;
    `}
  }
`

const BackgroundText = ({ type, price }) => {
  if (isEqual(type, 'Mountain')) {
    return (
      <DataItem>
        <DataText>
          Appartements et chalets<br />
          <b>À partir de {numeral(price).format('0,0 $')}</b><br />
        </DataText>
      </DataItem>
    )
  }

  return (
    <DataItem>
      <DataText>
        Appartements du studio au T5<br />
        <b>À partir de {numeral(price).format('0,0 $')}</b><br />
        <span>Prix direct promoteur</span>
      </DataText>
    </DataItem>
  )
}

const ProgramsAcquisitionBackground = ({ id, price, type }) => {
  const { placeholderImages, mountainImages } = useStaticQuery(graphql`{
    placeholderImages: allFile(
      filter: { relativeDirectory: { eq: "programs-acquisition" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
    mountainImages: allFile(
      filter: { relativeDirectory: { eq: "programs-acquisition-mountain" } }
      sort: { fields: relativePath }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            layout: FULL_WIDTH
          )
        }
      }
    }
  }`)

  const dataImages = isEqual(type, 'Mountain') ? mountainImages : placeholderImages
  const image = getImage(nth(get(dataImages, 'nodes'), id - 1) || first(get(dataImages, 'nodes')))
  const bgImage = convertToBgImage(image)

  return (
    <StyledImageSection
      alt='Programmes immobiliers neufs'
      Tag='div'
      {...bgImage}
      preserveStackingContext>
      <LogoContainer>
        <Logo
          blue={!isEqual(type, 'Mountain')}
          classic={true} />
      </LogoContainer>
      <DataContainer>
        <DataItem>
          <DataText>
            Livraison dès {(new Date()).getFullYear() + 1}
          </DataText>
        </DataItem>
      <BackgroundText type={type} price={price} />
      </DataContainer>
    </StyledImageSection>
  )
}

export default ProgramsAcquisitionBackground
