import React from 'react'
import styled from 'styled-components'

import Logo from '../../atoms/Logo'
import media from '../../../utils/media'
import SidebarForm from '../../molecules/SidebarForm'
import ProgramsAcquisitionTitle from '../../molecules/ProgramsAcquisitionTitle'
import ProgramsAcquisitionBackground from '../../molecules/ProgramsAcquisitionBackground'
import ProgramsAcquisitionDescription from '../../molecules/ProgramsAcquisitionDescription'

const SUBMIT = 'https://hooks.zapier.com/hooks/catch/2114713/o8qjm54'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const TopSection = styled.div`
  padding: 24px 72px;

  ${media.lessThan('md')`
    padding: 24px 40px;
  `}

  ${media.lessThan('sm')`
    padding: 24px;
  `}
`

const LogoContainer = styled.div`
  margin-bottom: 32px;
`

const FormSection = styled.div`
  padding: 0 32px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ProgramsAcquisitionMobile = ({ id, city, price, type, hiddenRef }) => (
  <Container>
    <TopSection>
      <LogoContainer>
        <Logo classic={true} />
      </LogoContainer>
      <ProgramsAcquisitionTitle city={city} />
      <ProgramsAcquisitionDescription city={city} type={type} />
    </TopSection>
    <ProgramsAcquisitionBackground
      id={id}
      type={type}
      price={price} />
    <FormSection>
      <SidebarForm
        city={city}
        submit={SUBMIT}
        redirection='/programme-neuf/demande-acquisition/success/'
        hiddenRef={hiddenRef} />
    </FormSection>
  </Container>
)

export default ProgramsAcquisitionMobile
