import React from 'react'
import styled from 'styled-components'
import { get, isEqual } from 'lodash'

import media from '../../../utils/media'

const Container = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  ${media.greaterThan('lg')`
    max-width: 368px;
  `}
`

const Title = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: ${({ theme }) => get(theme, 'blue')};
  margin-bottom: 8px;

  ${media.lessThan('sm')`
    font-size: 15px;
    line-height: 1.38;
  `}

  & > span {
    text-transform: capitalize;
  }
`

const Subtitle = styled(Title)`
  display: none;
  font-weight: normal;
  line-height: 1.25;

  ${media.lessThan('sm')`
    font-size: 14px;
    line-height: 1.43;
  `}
`

const ProgramsAcquisitionDescription = ({ city, type }) => {
  if (isEqual(type, 'Mountain')) {
    return (
      <Container>
        <Title>
          Recevez les plans et les prix de tous les appartements et chalets neufs à <span>{city}</span> qui vous correspondent.
        </Title>
      </Container>
    )
  }
  return (
    <Container>
      <Title>
        Recevez les plans et les prix de tous les appartements neufs {isEqual(city, 'Ile de France') ? 'en' : 'à'} <span>{city}</span> qui vous correspondent.
      </Title>
      <Subtitle>
        Découvrez des appartements livrés clé en main que vous pourrez personnaliser et qui offrent de magnifiques volumes et de beaux espaces extérieurs
      </Subtitle>
    </Container>
  )
}

export default ProgramsAcquisitionDescription
