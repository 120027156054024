import React, { useRef } from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { graphql, useStaticQuery } from 'gatsby'

import SEO from '../../atoms/Seo'
import media from '../../../utils/media'
import JsonLD from '../../atoms/JsonLD'
import numeral from '../../../config/number'
import ProgramsAcquisitionMobile from '../../organisms/ProgramsAcquisitionMobile'
import ProgramsAcquisitionDesktop from '../../organisms/ProgramsAcquisitionDesktop'
import ProgramsAcquisitionMobileButton from '../../organisms/ProgramsAcquisitionMobileButton'
import { deepReplace, replaceGraphKey } from '../../../utils/deepReplace'

const DesktopContainer = styled.div`
  display: block;

  ${media.lessThan('lg')`
    display:none;
  `}
`

const MobileContainer = styled.div`
  display: block;

  ${media.greaterThan('lg')`
    display:none;
  `}
`

const ProgramsAcquisitionPage = ({ pageContext, location }) => {
  const hiddenRef = useRef()
  const type = get(pageContext, 'type', '')
  const city = get(pageContext, 'city', '')
  const price = get(pageContext, 'price', 190000)
  const zipcode = get(pageContext, 'zipcode', '')

  const { site } = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            webpage {
              _context
              _type
              name
              url
              relatedLink
              specialty
            }
            organization {
              _context
              _type
              _id
              url
              image
              geo {
                _type
                latitude
                longitude
              }
              address {
                _type
                streetAddress
                addressLocality
                postalCode
                addressCountry {
                  _type
                  name
                }
              }
              name
              knowsAbout
            }
          }
        }
      }
    `)
  const { webpage, organization } = get(deepReplace(site, replaceGraphKey), 'siteMetadata')

  const title = `Programme Neuf - ${city} - ${zipcode}`
  const description = `Tous les appartements neufs à vendre à ${city} du 2 au 5 pièces à partir de ${numeral(price).format('0,0 $')}. Prix direct Promoteur. Terrasse, balcon, parking.`

  const keywords = ['Folhomee', 'Programme', 'Neuf', 'Immobilier', 'Appartement', city]
  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: `Folhomee ${city}`,
    description: description,
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: keywords.join(', '),
    makesOffer: {
      '@type': 'Offer',
      price: price,
      priceCurrency: 'EUR'
    },
    address: {
      '@type': 'PostalAddress',
      addressLocality: city,
      postalCode: zipcode
    }
  }

  return (
    <>
      <SEO
        title={title}
        location={location}
        description={description}>
          <JsonLD>
            {[webpage, organization, linkedData]}
          </JsonLD>
        </SEO>
      <DesktopContainer>
        <ProgramsAcquisitionDesktop
          id={1}
          type={type}
          city={city}
          price={price} />
      </DesktopContainer>
      <MobileContainer>
        <ProgramsAcquisitionMobileButton hiddenRef={hiddenRef}/>
        <ProgramsAcquisitionMobile
          id={1}
          type={type}
          city={city}
          price={price}
          hiddenRef={hiddenRef} />
      </MobileContainer>
    </>
  )
}

export default ProgramsAcquisitionPage
